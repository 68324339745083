<template>
  <div>
    <label for="customerName" class="mb-1">Customer name</label>
    <multiselect
      id="customerName"
      v-model="selectedValue"
      trackBy="user_guid"
      label="name"
      placeholder="Type to search customer"
      open-direction="bottom"
      :options="options"
      :multiple="true"
      :searchable="true"
      :loading="isLoading"
      :internal-search="true"
      :clear-on-select="true"
      :close-on-select="true"
      :limit="10"
      :max-height="400"
      :show-no-results="false"
      :hide-selected="false"
      :show-labels="false"
      @search-change="asyncFind"
      @select="onChange($event, 1)"
      @remove="onChange($event, 0)"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      options: [],
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      isLoading: false,
      selectedValue: this.value,
    };
  },
  async mounted() {
    this.getList();
  },
  watch: {
    async value(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      const { data } = await this.axios.get(`/ticket/filter/allCustomer`);
      if (data.result == 1 && data.detail.data) {
        this.options = data.detail.data.map((el) => {
          return {
            ...el,
            name: [el.name, el.telephone].filter((el) => el).join(", "),
          };
        });
      }
      this.isBusy = false;
    },

    asyncFind(query) {
      if (query && query.length) {
        this.filter.page = 1;
        this.filter.search = query;
        this.getList();
      } else {
        this.options = [];
      }
    },
    onChange() {
      this.$emit("change", this.selectedValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
